<template>
<div>
  <b-card-group deck>
    <b-card
    
      title=" View All Registered Children"
    >
        <downloadexcel
	class   = "btn btn-default"
	:data   = "items"
	name    = "RegisterChildren.xls">
Export
</downloadexcel>
  <TableView
  :headers="columns"
  :rows="items"      
  :sort="sort1"
>
  <template v-slot:items="{ row }">
    <td>{{ row.id }}</td>
    <td>{{ row.full_name }}</td>
    <td>{{ row.email }}</td>   
    <td>{{ row.age }}</td>              
    <td>{{ row.child_DOB }}</td>    
    <TablePagination></TablePagination>        
  </template>
  <template v-slot:no-data>
    <span>No data</span>
  </template>
</TableView>
    </b-card>  
  </b-card-group>
</div>
</template>

<script>
import downloadexcel from "vue-json-excel";
import TableView from "@/components/TableView.vue"
import TablePagination from "@/components/TablePagination.vue"
export default {
  components:{
    TableView,
    TablePagination,
    downloadexcel
  },
   data(){
    return{
      columns: [
        {
        key: 'id',
            label: 'ID',
        },
        {
        key: 'name',
        label: 'Full Name',
        },
        {
        key: 'email',
        label: 'Email',
        },
        {
        key: 'age',
        label: 'Age',
        },
        {
        key: 'child_DOB',
        label: 'Date of Birth',
        }
    
      ], // column data
      items: [
        {
          id: 1,
          full_name: "Shawna Dubbin",
          email: "sdubbin0@geocities.com",
           age:"2",
          child_DOB: "2015-01-20",
          registration_Date: "2015-07-20"
        },
        {
          id: 2,
          full_name: "Odette Demageard",
          email: "odemageard1@spotify.com",
           age:"7",
          child_DOB: "2018-11-20",
          registration_Date: "2019-07-20"
        },
        {
          id: 3,
          full_name: "Vera Taleworth",
          email: "vtaleworth2@google.ca",
           age:"5",
         child_DOB: "2019-01-04",
          registration_Date: "2020-12-30"
        },
        {
          id: 4,
          full_name: "Lonnie Izkovitz",
          email: "lizkovitz3@youtu.be",
           age:"3",
          child_DOB: "2014-01-20",
          registration_Date: "2015-07-20"
        },
        {
          id: 5,
          full_name: "Thatcher Stave",
          email: "tstave4@reference.com",
          age:"5",
         child_DOB: "2017-01-20",
          registration_Date: "2018-08-10"
        },
        {
          id: 6,
          full_name: "Karim Chipping",
          email: "kchipping5@scribd.com",
           age:"4",
          child_DOB: "2019-01-07",
          registration_Date: "2020-07-20"
        },
        
      ], // tabular data
      sort1:{
        field: 'full_name',
        order: 'asc'
      },    
      pagination:{
        itemsPerPage: 3,
        visualStyle: 'select'
      }
    }    
  },
  headers: {
  type: Array,
  default() {
    return []
  },
  required: true
},   
rows: {
  type: Array,
  default() {
    return []
  }, 
  required: true
},        
sort: {
  type: Object,
  default() {
    return {}
  }
},
//  page : Number,
// totalPages: Number,
//  paginationOptions: Object,
pagination: {
  type: Object,
  default() {
    return {}
  }
},
cssStyle: {
  type: String,
  default: 'ozn'
}

}
</script>
<style scoped>
.btn.btn-default {
      color: #1313ea;
    background-color: #F3F6F9;
    border-color: #F3F6F9;
    float: right;
}
</style>